import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import ReportSingle from "../components/report-single/report-single"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function FixturesPost({ data }) {

  const fixturesPost = data.allWpFixture?.nodes[0]
  const imageData = data?.wp?.siteSettings?.siteSettings?.globalInnerHero
  //Report Single data
  const reportData = fixturesPost?.fixtures
  return (
    <>
    <DefaultLayout data={data}>
      <div className="rs-breadcrumbs sec-color">
          <GatsbyImage
          image={getImage(imageData?.backgroundImage?.localFile)}
          alt={"Saigon Raiders Club"}
          className="inner-img"
        />
          <div className="breadcrumbs-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">Result</h1>
                  <ul>
                    <li>
                      <a className="active" href="/matches-events">
                        Fixtures Home &#187;{" "}
                      </a>
                    </li>
                    <li> Result</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
      <ReportSingle reportData={reportData}/>
      <div className="rs-club sec-spacer">
        <div className="container">
          <h3 className="title-bg">{fixturesPost.title}</h3>
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-md-12">
              <div className="rs-club-text">
              <h2>{`Stadium: ${fixturesPost?.fixtures?.stadium}`}</h2>
              <h3>{`Time: ${fixturesPost?.fixtures?.fixtureDate}`}</h3>
              <img src={fixturesPost?.featuredImage?.node?.sourceUrl} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      </DefaultLayout>
    </>
  )
}
export const query = graphql`
  query ($slug: String!) {
    wp {
      siteSettings {
        siteSettings {
          globalInnerHero {
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, height: 380)
                }
              }
            }
          }
        }
      }
    }
    allWpFixture(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        fixtures {
          awayTeam
          awayTeamLogo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          homeTeam
          fixtureDate
          homeTeamLogo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          score
          stadium
          time
          matchStatistics {
            awayTeamScore
            fieldGroupName
            homeTeamScore
            metrics
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`
export default FixturesPost
