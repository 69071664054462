import React from "react"
import "./report-single.scss"
import { StaticImage } from "gatsby-plugin-image"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function ReportSingle({reportData}) {

    return (
        <div className="rs-result-single">
	        <div className="container">
	        	<div className="text-center">
	        		<h3 className="title-bg">Match Statictics</h3>
	        	</div>
	        	<div className="match-stats">
	        		<div className="overly-bg"></div>
	        		<table>
        				<tr>
        					<th className="team-name stats1">
        						<a href="#">{reportData?.homeTeam}
                                <GatsbyImage
                                image={getImage(reportData?.homeTeamLogo?.localFile)}
                                alt=""
                                className="result-img"
                                />      
                                </a>
        						{/* <span>Stuard (32'), Rowal (52'), Peter (83')</span> */}
        					</th>
        					<th className="team-score"><span className="goal-count">{reportData?.score}</span></th>
        					<th className="team-name stats2">
        						<a href="#">
                                <GatsbyImage
                                image={getImage(reportData?.awayTeamLogo?.localFile)}
                                alt=""
                                className="result-img"
                                />      
                                {reportData?.awayTeam}
                                </a>
        						{/* <span>Robery (42'), Lampard (91')</span> */}
        					</th>
        				</tr>
                        {reportData?.matchStatistics?.map((el, index) => {
                            return (
                                <tr key={index}>
                                    <td><span className="stats1">{el?.homeTeamScore ? el?.homeTeamScore : 0}</span></td>
                                    <td><span className="stat-title">{el?.metrics}</span></td>
                                    <td><span className="stats2">{el?.awayTeamScore ? el?.awayTeamScore : 0}</span></td>
    					        </tr>
                            )
                        })}
    				</table>
	        	</div>
			</div>
        </div>
    )
}